/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ReactDOM from "react-dom/client";
import { CustomerlyProvider } from "react-live-chat-customerly";
import { PlaceholderError, Toast, ToastProvider } from "@frontend-monorepo/design-system";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import dayjs from "dayjs";
import it from "dayjs/locale/it";

import ErrorBoundary from "components/error-boundary";
import Routes from "./routes";

import "./index.css";
dayjs.locale(it)

window.addEventListener("vite:preloadError", (event) => {
	event.preventDefault()
	window.location.reload()
})

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root")!);


root.render(
	<ErrorBoundary
		fallback={<PlaceholderError />}
		onError={(error) => Sentry.captureEvent(error)}
	>
		<QueryClientProvider client={queryClient}>
			<CustomerlyProvider appId="d915533c">
				<ToastProvider>
					<Routes />
					<Toast />
				</ToastProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</CustomerlyProvider>
		</QueryClientProvider>
	</ErrorBoundary>,
);
