import ErrorPlaceholder from "../../../assets/error.svg";
import { Button } from "../../button";
import { Typography } from "../../typography";

const PlaceholderError = () => {
	return (
		<div className="flex size-full h-screen flex-1 flex-col items-center justify-center">
			<img className="mb-4" src={ErrorPlaceholder} alt="" />
			<Typography variant="label-1" className="mb-8 text-mint-500">Si è verificato un errore</Typography>
			<Button onClick={() => window.location.reload()} as="button" size="md">Ricarica la pagina</Button>
		</div>
	);
};

export default PlaceholderError;
