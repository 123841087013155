import * as Sentry from "@sentry/react";

const initSentry = () => {
	Sentry.init({
		dsn: "https://25daaa35f6816b0a9bd8a02cc95be197@o4507804125560832.ingest.de.sentry.io/4507804127461456",
		integrations: [
			Sentry.browserTracingIntegration(),
		],
		// Tracing
		tracesSampleRate: 0.1, //  Capture 10% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [],
		// Session Replay
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 0,
	});
};

export default initSentry;
