import React, { ReactNode } from "react";

interface ErrorBoundaryProps {
	fallback: ReactNode;
	children: ReactNode;
	onError?: (error: Error, info: string) => void;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error): ErrorBoundaryState {
		return { hasError: true };
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		// If an onError function is provided, call it
		if (this.props.onError) {
			this.props.onError(error, info.componentStack || "");
		}
	}

	render() {
		if (this.state.hasError) {
			return this.props.fallback;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
