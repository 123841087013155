import { lazy, useCallback, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useCustomerly } from "react-live-chat-customerly";
import initSentry from "helpers/sentry";

import config from "config";

const Master = lazy(() => import("./master/master"));
const Talent = lazy(() => import("./talent/talent"));


const tagManagerArgs = {
	gtmId: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER,
};

const Routes = () => {
	const { load } = useCustomerly();
	const { host } = window.location;

	const loadServices = useCallback(() => {
		TagManager.initialize(tagManagerArgs);

		if (config.isProduction) {
			load({ direction: "right" });
			initSentry();
		}
	}, [load]);

	useEffect(() => {
		loadServices();
	}, [loadServices]);

	return (
		host.startsWith(config.masterSubdomain) ? <Master /> : <Talent />
	);
};

export default Routes;
